import { API as WebAPI } from "remote/api/WebAPI";

export function getWorkgroupList() {
  return WebAPI.get("/Setting/GetWorkGroups");
}

export function getWorkgroup(id) {
  return WebAPI.get(`/Organization/WorkGroup/${id}`);
}

export function getEventList(workgroupId) {
  return WebAPI.get("/Setting/GetOrganizations", {
    params: {
      workgroupid: workgroupId
    }
  });
}

export function getEvent(id) {
  return WebAPI.get(`/Organization/Organization/${id}`);
}

export function getNavigationSettings() {
  return WebAPI.get("/Setting/GetSelected");
}

export function saveNavigationSettings(workgroupId, eventId) {
  return WebAPI.post("/Setting/ChangeSelected", {
    workgroupid: workgroupId,
    organizationid: eventId
  });
}
