import React, { useReducer } from "react";
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

export let globalState;
export let globalDispatch;

const name = "SaveNotifierSlice";

const initialState = {
  shaking: false,
  resources: {
    // "resource-id": {
    //   pending: false,
    //   dirty: false,
    //   onReset: () => {},
    //   onSave: () => {}
    // }
  }
};

export const { actions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    add_resource: (state, action) => {
      const { id, resource } = action.payload;
      if (!state.resources[id])
        state.resources[id] = { ...resource, pending: false };
      else state.resources[id] = { ...state.resources[id], ...resource };
    },

    remove_resource: (state, action) => {
      const { id } = action.payload;
      delete state.resources[id];
    },

    set_resource_pending: (state, action) => {
      const { id, pending } = action.payload;
      state.resources[id].pending = pending;
    },

    set_shaking: (state, action) => {
      const { shaking } = action.payload;
      state.shaking = shaking;
    }
  }
});

export const SaveNotifierContext = React.createContext({});

// XXX: There MUST be at most 1 Save Notifier Provider!
// Otherwise global variables MIGHT shatter
export function SaveNotifierProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  globalState = state;
  globalDispatch = dispatch;

  const value = {
    getResources: () => state.resources,

    hasUnsavedResources: () =>
      Object.keys(state.resources).some(id => state.resources[id].dirty),

    addResource: ({ id, ...resource }) =>
      dispatch(actions.add_resource({ id, resource })),

    removeResource: id => dispatch(actions.remove_resource({ id })),

    setResourcePending: (id, pending) =>
      dispatch(actions.set_resource_pending({ id, pending })),

    isShaking: () => state.shaking,

    shakeNotifier: (shake = true) =>
      dispatch(actions.set_shaking({ shaking: shake }))
  };

  return (
    <SaveNotifierContext.Provider value={value}>
      {children}
    </SaveNotifierContext.Provider>
  );
}

export const SaveNotifierConsumer = SaveNotifierContext;

export function useSaveNotifier({ id, onReset, onSave, resource }, depts = []) {
  const saveNotifier = React.useContext(SaveNotifierContext);

  // -- Effects
  React.useEffect(() => {
    saveNotifier.addResource({
      id,
      onReset,
      onSave,
      dirty: resource.dirty,
      handleSubmit: resource.form.handleSubmit,
      errors: resource.form.errors
    });
  }, [
    id,
    resource.dirty,
    resource.pending,
    resource.form.handleSubmit,
    resource.form.errors,
    ...depts
  ]);

  React.useEffect(() => {
    return () => saveNotifier.removeResource(id);
  }, [id]);

  React.useEffect(() => {
    saveNotifier.setResourcePending(id, resource.saving);
  }, [resource.pending, resource.saving]);

  // -- Hook
  return saveNotifier;
}
