import { createSlice } from "@reduxjs/toolkit";
import { all, put, call, select, takeLatest } from "redux-saga/effects";

import * as NProgressUtil from "util/nprogress.util";
import { notifyError } from "util/toast.util";
import { getNavigationSettings } from "remote/crud/workgroups.crud";
import { getWorkgroup } from "remote/crud/workgroups.crud";
import { getEvent } from "remote/crud/workgroups.crud";
import { saveNavigationSettings } from "remote/crud/workgroups.crud";

import { actions as MuteActions } from "store/duck/mute.duck";

const name = "navigation";

const initialState = {
  fetched: false,
  pending: false,
  error: null,
  lastVisitedAttendee: {
    attendeeId: null
  },
  activeWorkgroup: {
    id: null,
    title: null,
    description: null,
    color: 0x0
  },
  activeEvent: {
    id: null,
    workgroupId: null,
    title: null,
    description: null,
    type: null
  }
};

const persistConfig = storage => ({
  storage,
  key: name,
  whitelist: ["lastVisitedAttendee"]
});

const slice = createSlice({
  name,
  initialState,
  reducers: {
    fetch_request: state => {
      state.pending = true;
      state.fetched = false;
    },

    fetch_success: (state, action) => {
      const { activeWorkgroup, activeEvent } = action.payload;
      state.pending = false;
      if (activeWorkgroup) {
        state.activeWorkgroup = {
          id: activeWorkgroup.workGroupId,
          title: activeWorkgroup.title,
          description: activeWorkgroup.description
        };
      }
      if (activeEvent) {
        state.activeEvent = {
          id: activeEvent.organizationId,
          workgroupId: activeEvent.workGroupId,
          title: activeEvent.title,
          description: activeEvent.description,
          type: activeEvent.organizationType
        };
      }
      state.fetched = true;
    },

    fetch_fail: (state, action) => {
      const { error } = action.payload;
      state.pending = false;
      state.fetched = false;
      state.error = error;
    },

    update_event_title: (state, action) => {
      const { title } = action.payload;
      state.activeEvent.title = title;
    },

    enter_workgroup: (state, action) => {
      const { workgroup } = action.payload;
      state.activeWorkgroup = workgroup;
    },

    leave_workgroup: state => {
      state.activeWorkgroup = { ...initialState.activeWorkgroup };
      state.activeEvent = { ...initialState.activeEvent };
    },

    enter_event: (state, action) => {
      const { event } = action.payload;
      state.activeEvent = event;
    },

    leave_event: state => {
      state.activeEvent = { ...initialState.activeEvent };
    },

    update_last_visited_attendee: (state, action) => {
      console.log(action);
      state.lastVisitedAttendee = action.payload;
    }
  }
});

const selectors = {
  getActiveWorkgroup: state => state[name].activeWorkgroup,

  getActiveEvent: state => state[name].activeEvent,

  getLastVisitedAttendee: state => state[name].lastVisitedAttendee,

  isPending: state => state[name].pending,

  isFetched: state => state[name].fetched
};

const sagaWorkers = {
  fetch: function*(action) {
    try {
      NProgressUtil.push("navigation-setting");
      const response = yield call(getNavigationSettings);
      const { data } = response;
      const { activeWorkgroup, activeEvent } = yield all({
        activeWorkgroup:
          data.activeWorkGroupId !== null &&
          call(getWorkgroup, data.activeWorkGroupId),
        activeEvent:
          data.activeEventId !== null && call(getEvent, data.activeEventId)
      });
      yield put(
        actions.fetch_success({
          activeWorkgroup: activeWorkgroup.data,
          activeEvent: activeEvent.data
        })
      );
    } catch (error) {
      yield notifyError({
        errorCode: error.response?.status,
        message: "TODO: Notify error!",
        autoClose: false
      });
      yield put(actions.fetch_fail({ error }));
    } finally {
      NProgressUtil.pop("navigation-setting");
    }
  },

  notifyServer: function*(action) {
    try {
      const activeWorkgroup = yield select(selectors.getActiveWorkgroup);
      const activeEvent = yield select(selectors.getActiveEvent);
      const response = yield call(
        saveNavigationSettings,
        activeWorkgroup.id,
        activeEvent.id
      );
    } catch (error) {
      yield notifyError({
        errorCode: error.response?.status,
        message: "Failed to sync workgroup with the server.",
        autoClose: false
      });
    }
  },

  acceptEvent: function*(action) {
    yield put(MuteActions.acceptEvent({ id: action.payload.event.id }));
  },

  resetAcceptedEvent: function*() {
    yield put(MuteActions.resetEvent());
  }
};

const saga = function*() {
  yield takeLatest(
    [
      actions.enter_workgroup,
      actions.leave_workgroup,
      actions.enter_event,
      actions.leave_event
    ],
    sagaWorkers.notifyServer
  );

  yield takeLatest([actions.fetch_request], sagaWorkers.fetch);

  yield takeLatest([actions.enter_event], sagaWorkers.acceptEvent);

  yield takeLatest(
    [actions.leave_workgroup, actions.leave_event],
    sagaWorkers.resetAcceptedEvent
  );
};

export const { reducer, actions } = slice;
export { selectors, saga };
export { persistConfig };
export default slice;
