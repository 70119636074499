import React from "react";
import { history } from "store";
import { Route, Switch } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { HotKeys } from "react-hotkeys";
import "react-toastify/dist/ReactToastify.css";
import "twix";

import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";
import PersistLoader from "containers/PersistLoader";
import RoutersRoot from "./routers.root";

import { SaveNotifierProvider } from "hooks/save-notifier.context";

// Apps configurations
import { store, persistor } from "./apps.configs.js";
import { keyMap } from "./apps.configs.js";

const AppsRoot = () => {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={<PersistLoader />} persistor={persistor}>
        <ConnectedRouter history={history}>
          <SaveNotifierProvider>
            <HotKeys keyMap={keyMap} className="app-main">
              <Switch>
                <Route path="/" component={RoutersRoot} />
              </Switch>
            </HotKeys>
          </SaveNotifierProvider>
        </ConnectedRouter>
      </PersistGate>
    </ReduxProvider>
  );
};

export default AppsRoot;
