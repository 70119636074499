import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const name = "printers";

const initialState: DuckPrinters.State = {
  associations: {}
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    associate: (
      state,
      action: PayloadAction<{ templateId: string; printerName: string }>
    ) => {
      const { templateId, printerName } = action.payload;
      state.associations[templateId] = printerName;
    },

    remove: (state, action) => {
      const { templateId } = action.payload;
      delete state.associations[templateId];
    }
  }
});

const persistConfig = (storage: any) => ({
  storage,
  key: name
});

const selectors = {
  getPrinterAssociations: (state: any) =>
    (state[name] as DuckPrinters.State).associations
};

export const { reducer, actions } = slice;
export { selectors };
export { persistConfig };
export default slice;
