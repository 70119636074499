import generateStore from "store";
import { toast } from "react-toastify";
import { configure as configureHotkeys } from "react-hotkeys";

// Mixin Lodash functions
import "util/lodash.mixin";

// Declare default Toastify configs
toast.configure({
  pauseOnFocusLoss: true,
  style: { borderRadius: "10px" }
});

// Generate Store
export const { store, persistor } = generateStore();

// Configure HotKeys
configureHotkeys({
  defaultComponent: "div",
  ignoreTags: []
});

// Declare HotKeys keymap
export const keyMap = {
  REFRESH: "ctrl+r",
  ESCAPE: "esc"
};
