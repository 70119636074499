import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";

import rootReducer from "store/reducers.root";
import rootSaga from "store/sagas.root";

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routeMiddleware];

export default function generateStore() {
  const persistedReducer = rootReducer(history);

  const store = configureStore({
    reducer: persistedReducer,
    middleware: middlewares
  });

  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store);

  // if (module.hot) {
  //   // Enable Webpack hot module replacement for reducers
  //   module.hot.accept("store/reducers.root", () => {
  //     const nextRootReducer = require("store/reducers.root");
  //     store.replaceReducer(nextRootReducer);
  //   });
  // }

  return { store, persistor };
}

export { history };
