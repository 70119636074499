import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { createTransform } from "redux-persist";

const name = "mute";

const initialState = {
  mutedNotes: {
    // GUID -> timestamp
  },
  acceptedEvent: {
    id: "", // GUID
    acceptedTimestamp: 0 // UNIX timestamp
  }
};

const deleteExpiredOnes = createTransform(
  (inboundState, key) => {
    return { ...inboundState, [key]: inboundState[key] };
  },
  (outboundState, key) => {
    if (key === "mutedNotes") {
      const now = Date.now();
      const mutedNotes = outboundState;
      for (let guid in mutedNotes) {
        if (now > mutedNotes[guid]) {
          delete mutedNotes[guid];
        }
      }
      return { ...mutedNotes };
    }

    return { ...outboundState };
  }
);

const persistConfig = storage => ({
  storage,
  key: name,
  transforms: [deleteExpiredOnes]
});

const slice = createSlice({
  name,
  initialState,
  reducers: {
    mute: (state, action) => {
      const { guid, timestamp } = action.payload;
      state.mutedNotes[guid] = timestamp;
    },
    unmute: (state, action) => {
      const { guid } = action.payload;
      delete state.mutedNotes[guid];
    },
    acceptEvent: (state, action) => {
      const { id } = action.payload;
      const now = _.now();
      state.acceptedEvent = {
        id,
        acceptedTimestamp: now
      };
    },
    resetEvent: state => {
      state.acceptedEvent = {
        id: "",
        acceptedTimestamp: 0
      };
    }
  }
});

const selectors = {
  getMutedTimestamps: state => state[name].mutedNotes,
  getAcceptedEvent: state => state[name].acceptedEvent
};

export const { reducer, actions } = slice;
export { selectors };
export { persistConfig };
export default slice;
