import { compose } from "redux";
import { connectRouter } from "connected-react-router";
import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Ducks
import LayoutDuck from "store/duck/layout.duck";
import AuthDuck from "store/duck/auth.duck";
import PermsDuck from "store/duck/perms.duck";
import NavigationDuck from "store/duck/navigation.duck";
import SettingsDuck from "store/duck/settings.duck";
import MuteDuck from "store/duck/mute.duck";
import PrintersDuck from "store/duck/printers.duck";

// Persistor Blacklists
import { persistConfig as AuthPersistConfig } from "store/duck/auth.duck";
import { persistConfig as NavPersistConfig } from "store/duck/navigation.duck";
import { persistConfig as MutePersistConfig } from "store/duck/mute.duck";
import { persistConfig as PrintersPersistConfig } from "store/duck/printers.duck";

// Persistor Jazz
const composeEnhancers =
  window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootPersistorConfig = {
  storage,
  key: "root",
  enchancer: composeEnhancers(),
  whitelist: [LayoutDuck.name]
};

// Root Reducers
export default history => {
  // prettier-ignore
  const rootReducer = combineReducers({
    router: connectRouter(history),
    [LayoutDuck.name]: LayoutDuck.reducer,
    [AuthDuck.name]: AuthDuck.reducer,
    [PermsDuck.name]: PermsDuck.reducer,
    [NavigationDuck.name]: persistReducer(NavPersistConfig(storage), NavigationDuck.reducer),
    [SettingsDuck.name]: SettingsDuck.reducer,
    [MuteDuck.name]: persistReducer(MutePersistConfig(storage), MuteDuck.reducer),
    [PrintersDuck.name]: persistReducer(PrintersPersistConfig(storage), PrintersDuck.reducer)
  });

  return persistReducer(rootPersistorConfig, rootReducer);
};
