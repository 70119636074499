import React, { useState } from "react";

import CircularProgress from "components/CircularProgress";
import * as NProgressUtil from "util/nprogress.util";
import "react-placeholder/lib/reactPlaceholder.css";
import "nprogress/nprogress.css";

export const ConditionalWrap = ({ condition, wrapper, children }) => {
  return condition ? wrapper(children) : children;
};

export const AsyncComponent = ({ thunk, ...otherProps }) => {
  const [component, setComponent] = useState(null);

  React.useEffect(() => {
    if (component === null) {
      let canceled = false;
      const loadComponent = async () => {
        const { default: Component } = await thunk();

        if (!canceled) setComponent(<Component {...otherProps} />);
        NProgressUtil.pop("AsyncComponent");
      };

      NProgressUtil.push("AsyncComponent");
      loadComponent();
      return () => (canceled = true);
    }
  }, [component]);

  return (
    <>
      {component || (
        <div className="loader-view" style={{ height: "calc(100vh - 200px)" }}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export const AsyncComponentHOC = thunk => {
  return ({ ...props }) => <AsyncComponent thunk={thunk} {...props} />;
};
