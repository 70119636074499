import _ from "lodash";

_.mixin({
  firstOutcome: function(...pairs) {
    for (let [condition, outcome] of pairs) {
      if (condition === true) return outcome;
    }
  }
});

_.mixin({
  replaceElement: function(array, where, replaceWith) {
    const index = _.findIndex(array, where);
    if (index === -1) return array;
    array.splice(index, 1, replaceWith);
    return array;
  }
});

_.mixin({
  replaceElementCopied: function(array, where, replaceWith) {
    const newArray = [...array];
    _.replaceElement(newArray, where, replaceWith);
    return newArray;
  }
});

_.mixin({
  rhf_shiftFormArrayIndex: (formValues, fieldName, removedIndex) => {
    const omittedValues = _.omit(formValues, [`${fieldName}[${removedIndex}]`]);
    const regex = new RegExp(`\\Q${fieldName}\\E` + "\\[(\\d+)\\]");
    return _.mapKeys(omittedValues, (value, key) => {
      const parts = regex.exec(key);
      if (!parts) return key;

      const currentIndex = parseInt(parts[1], 10);
      return currentIndex > removedIndex
        ? `${fieldName}[${currentIndex - 1}]`
        : key;
    });
  }
});

_.mixin({
  getObjectPath: (object, propertyName) => {
    let parts = propertyName.split("."),
      length = parts.length,
      i,
      property = object || this;

    for (i = 0; i < length; i++) {
      property = property[parts[i]];
    }

    return property;
  },

  // Fetched from -> https://codereview.stackexchange.com/questions/194338/safely-setting-object-properties-with-dot-notation-strings-in-javascript
  setObjectPath: (obj, path, value) => {
    // protect against being something unexpected
    obj = typeof obj === "object" ? obj : {};
    // split the path into and array if its not one already
    var keys = Array.isArray(path) ? path : path.split(".");
    // keep up with our current place in the object
    // starting at the root object and drilling down
    var curStep = obj;
    // loop over the path parts one at a time
    // but, dont iterate the last part,
    for (var i = 0; i < keys.length - 1; i++) {
      // get the current path part
      var key = keys[i];

      // if nothing exists for this key, make it an empty object or array
      if (
        !curStep[key] &&
        !Object.prototype.hasOwnProperty.call(curStep, key)
      ) {
        // get the next key in the path, if its numeric, make this property an empty array
        // otherwise, make it an empty object
        var nextKey = keys[i + 1];
        var useArray = /^\+?(0|[1-9]\d*)$/.test(nextKey);
        curStep[key] = useArray ? [] : {};
      }
      // update curStep to point to the new level
      curStep = curStep[key];
    }
    // set the final key to our value
    var finalStep = keys[keys.length - 1];
    curStep[finalStep] = value;
  }
});

_.mixin({
  using: (item, callback) => callback(item)
});

_.mixin({
  parseSearchParams: search =>
    _.reduce(
      search.substr(1).split("&"),
      (obj, expr) => {
        const [key, value] = expr.split("=");
        obj[key] = value;
        return obj;
      },
      {}
    )
});

_.mixin({
  digiconTableSort(list, sorters, keyPriority) {
    const orders = _.reduce(
      keyPriority,
      (orders, key) => {
        const order = sorters[key];
        if (order !== undefined) orders[key] = sorters[key].toLowerCase();
        return orders;
      },
      {}
    );

    return _.orderBy(list, _.keys(orders), _.values(orders));
  }
});
