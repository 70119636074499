import axios from "axios";
import { history } from "store";
import { AuthTokenCookie } from "store/cookies";

const API = axios.create({
  baseURL: "https://veri.workindigi.com",
  // validateStatus: status => true, // Accept even 500! They are handled internally
  timeout: 30_000
});

// Verbose loggers
API.interceptors.request.use(request => {
  if (
    process.env.NODE_ENV === "development" ||
    /* Here for dev server -> */ true
  ) {
    const method = request.method.toUpperCase();
    const url = request.url.replace(request.baseURL, "");
    console.debug(`< [REQ][WebAPI] ${method} - ${url}`, request.data || "");
  }
  return request;
});
API.interceptors.response.use(response => {
  const method = response.config?.method?.toUpperCase();
  const url = response.config?.url?.replace(response.config?.baseURL, "");
  const status = response.status;
  console.debug(
    `> [${status}][WebAPI] ${method} - ${url}`,
    response.data || ""
  );
  return response;
});

// Auth token interceptors
API.interceptors.request.use(request => {
  const authToken = AuthTokenCookie.get();
  if (authToken) {
    request.headers.Authorization = `Bearer ${authToken}`;
  }
  return request;
});
API.interceptors.response.use(null, err => {
  if (err.response?.status === 401) {
    AuthTokenCookie.erase();
    history.push("/login");
  }
  return Promise.reject(err);
});

export { API };
