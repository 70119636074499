import { createSlice } from "@reduxjs/toolkit";
import { takeLatest } from "redux-saga/effects";
import { updateLocale } from "i18n";

import {
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
  BELOW_THE_HEADER
} from "constants/SettingConsts";
import { DEEP_ORANGE } from "constants/ThemeColors";

const rltLocale = ["ar-SA"];

const slice = createSlice({
  name: "layout",
  initialState: {
    navCollapsed: false,
    drawerType: FIXED_DRAWER,
    themeColor: DEEP_ORANGE,
    darkTheme: false,
    width: window.innerWidth,
    isDirectionRTL: false,
    navigationStyle: HORIZONTAL_NAVIGATION,
    horizontalNavPosition: BELOW_THE_HEADER,
    zoom: 1.0,
    locale: {
      languageId: "english",
      locale: "en-US",
      name: "English",
      icon: "us"
    }
  },
  reducers: {
    "@@router/LOCATION_CHANGE": state => ({
      // TODO: Currently handles only "layout/@@router/LOCATION_CHANGE"
      ...state,
      navCollapsed: false
    }),
    toggle_collapsed_nav: (state, action) => ({
      ...state,
      navCollapsed: action.isNavCollapsed
    }),
    window_width: (state, action) => ({
      ...state,
      width: action.width
    }),
    theme_color: (state, action) => ({
      ...state,
      darkTheme: false,
      themeColor: action.color
    }),
    switch_language: (state, action) => {
      state.locale = action.payload;
      state.isDirectionRTL = rltLocale.includes(action.payload.locale);
    },
    change_direction: (state, action) => ({
      ...state,
      isDirectionRTL: !state.isDirectionRTL
    }),
    change_navigation_style: (state, action) => ({
      ...state,
      navigationStyle: action.payload
    }),
    horizontal_menu_position: (state, action) => ({
      ...state,
      horizontalNavPosition: action.payload
    }),
    change_zoom: (state, action) => ({
      ...state,
      zoom: action.payload
    })
  }
});

const selectors = {
  getLocale: state => state["layout"].locale,
  getWidth: state => state["layout"].width,
  getZoom: state => state["layout"].zoom
};

const saga = function*() {
  yield takeLatest(slice.actions.switch_language, function*(action) {
    yield updateLocale(action.payload.locale);
  });
};

export const { reducer, actions } = slice;
export { saga, selectors };
export default slice;
