import { API as AuthAPI } from "remote/api/AuthAPI";

export function login(email, password) {
  return AuthAPI.post("/users/authenticate", { email, password });
}

export function getMe() {
  return AuthAPI.get("/users/me");
}

export function changeLocale(locale) {
  return AuthAPI.post("/Setting/ChangeLanguage", {
    language: locale
  });
}
