import { createSlice } from "@reduxjs/toolkit";
import { put, call, takeLatest } from "redux-saga/effects";

import { notifyError } from "util/toast.util";
import { AuthTokenCookie } from "store/cookies";
import { login, getMe } from "remote/crud/auth.crud";
import intl, { updateLocale } from "i18n";

const name = "auth";

const initialState = {
  waiting: false,
  role: null,
  lastRefreshed: null,
  roles: [],
  user: {
    userId: null,
    email: null,
    firstName: null,
    lastName: null,
    agencyPermissions: null,
    language: "en-US"
  }
};

const persistConfig = storage => ({
  storage,
  key: name,
  blacklist: ["waiting"]
});

const slice = createSlice({
  name,
  initialState,
  reducers: {
    request_login: store => ({
      ...store,
      waiting: true
    }),

    update_token: (store, action) => {
      AuthTokenCookie.set(action.payload.token);
      return {
        ...store,
        waiting: false,
        roles: action.payload.perm,
        lastRefreshed: new Date().toISOString()
      };
    },

    fail_login: store => ({
      ...store,
      waiting: false
    }),

    request_me: store => ({
      ...store,
      waiting: true
    }),

    update_me: (store, action) => ({
      ...store,
      user: action.payload,
      waiting: false
    }),

    update_language: (store, action) => {
      store.user.language = action.payload;
    },

    logout: store => {
      AuthTokenCookie.erase();
      return {
        ...initialState
      };
    }
  }
});

const selectors = {
  getUser: state => state[name].user,

  getAuthInfo: state => ({
    token: AuthTokenCookie.get(),
    role: state[name].role,
    lastRefreshed: state[name].lastRefreshed
  }),

  isWaiting: state => state[name].waiting,

  getRoles: state => state[name].roles
};

const sagaWorkers = {
  login: function*(action) {
    const { update_token, fail_login } = slice.actions;
    const { email, password } = action.payload;

    try {
      const response = yield call(login, email, password);
      yield put(slice.actions.update_token(response.data));
      yield put(slice.actions.request_me());
    } catch (err) {
      console.log(err?.response);
      const { errors } = err?.response?.data || {};
      if (errors?.[0]?.errorcode === "err-1") {
        yield notifyError({
          message: intl.formatMessage({ id: "error.login.invalid" }),
          autoClose: false
        });
      } else {
        yield notifyError({
          message: errors?.[0]?.errorcode,
          autoClose: false
        });
      }
      yield put(slice.actions.fail_login());
    }
  },

  fetchMe: function*(action) {
    const { update_me, fail_login } = slice.actions;

    try {
      const response = yield call(getMe);
      yield put(update_me(response.data));
      updateLocale(response.data.language);
    } catch (err) {
      console.log(err?.response);
      const { errors } = err?.response?.data || {};
      yield notifyError({
        message: errors?.[0]?.errorcode,
        autoClose: false
      });
      yield put(fail_login());
    }
  }
};

const saga = function*() {
  yield takeLatest([slice.actions.request_login], sagaWorkers.login);

  yield takeLatest([slice.actions.request_me], sagaWorkers.fetchMe);
};

export const { reducer, actions } = slice;
export { selectors, saga };
export { persistConfig };
export default slice;
