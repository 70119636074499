import red from "@material-ui/core/colors/red";

const digiconOrange = "#ff8c00";
const digiconPurple = "#422f69";
const tooltipBackgroundColor = "rgba(56, 39, 89, 0.9)";

const MuiTooltip = {
  popper: {
    filter: "drop-shadow(0 0 2px black)"
  },
  tooltip: {
    backgroundColor: tooltipBackgroundColor
  },
  arrow: {
    "&::before": {
      color: tooltipBackgroundColor
    }
  }
};

const MuiOutlinedInput = {
  root: {
    background: "rgba(255, 255, 255, 0.6)"
  }
};

const MuiPickersToolbar = {
  toolbar: {
    backgroundColor: digiconPurple
  }
};

const MuiPickersDay = {
  daySelected: {
    backgroundColor: digiconPurple
  }
};

const MuiButton = {
  containedPrimary: {
    backgroundColor: digiconPurple
  },
  outlinedPrimary: {
    color: digiconPurple,
    borderColor: digiconPurple,
    "&:hover": {
      transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
      color: digiconOrange
    }
  }
};

export default {
  palette: {
    primary: {
      light: digiconOrange,
      main: digiconOrange,
      dark: digiconOrange,
      contrastText: "#fff"
    },
    secondary: {
      light: red[300],
      main: red["A200"],
      dark: red[700],
      contrastText: "#fff"
    }
  },
  status: {
    danger: "orange"
  },
  typography: {
    button: {
      fontWeight: 400,
      textAlign: "capitalize"
    }
  },
  overrides: {
    MuiTooltip,
    MuiOutlinedInput,
    MuiPickersToolbar,
    MuiPickersDay,
    MuiButton
  }
};
