import { API as WebAPI } from "remote/api/WebAPI";

import { toPeriodId } from "constants/EventPeriods";

// -- Attendee Titles
export function getAttendeeTitles() {
  return WebAPI.get("/Definition/AcademicTitle");
}

export function updateAttendeeTitle(titleId, title) {
  return WebAPI.post("/Definition/AcademicTitle", {
    academicTitleId: titleId,
    name: title.name,
    description: ""
  });
}

export function deleteAttendeeTitle(titleId) {
  return WebAPI.delete(`/Definition/AcademicTitle/${titleId}`);
}

export function createAttendeeTitle(title) {
  return WebAPI.post("/Definition/AcademicTitle", {
    name: title.name,
    description: ""
  });
}

// -- Attendance Types
export function getAttendanceTypes() {
  return WebAPI.get("/Definition/AttendanceType");
}

export function getAttendanceType(id) {
  return WebAPI.get(`/Definition/AttendanceType/${id}`);
}

export function createAttendanceType(attendanceType) {
  return WebAPI.post("/Definition/AttendanceType", {
    name: attendanceType.name,
    color: attendanceType.color,
    prices: {
      earlyPrice: attendanceType.prices.early,
      latePrice: attendanceType.prices.late,
      onsitePrice: attendanceType.prices.onSite
    }
  });
}

export function updateAttendanceType(attendanceType) {
  return WebAPI.post("/Definition/AttendanceType", {
    attendanceTypeId: attendanceType.id,
    name: attendanceType.name,
    color: attendanceType.color,
    prices: {
      earlyPrice: attendanceType.prices.early,
      latePrice: attendanceType.prices.late,
      onsitePrice: attendanceType.prices.onSite
    }
  });
}

export function deleteAttendanceType(attendanceTypeId) {
  return WebAPI.delete(`/Definition/AttendanceType/${attendanceTypeId}`);
}

// -- Attendees
export function getAllAttendees({ ...searchQueries }) {
  return WebAPI.post("/Attendee/GetAllAttendees_Table_V1", searchQueries);
}

export function getAttendees({ ...searchQueries }) {
  return WebAPI.post("/Attendee/MainAttendances_Table_V1", searchQueries);
}

export function getAttendeesWithNotes({ ...searchQueries }) {
  return WebAPI.post("/Attendee/MainAttendanceNotes_Table_V1", searchQueries);
}

export function getAttendee(attendeeId) {
  return WebAPI.get(`/Attendee/Attendance/${attendeeId}`);
}

export function quickCreateAttendee(quickAttendee) {
  return WebAPI.post("/Attendee/AttendanceWithoutCheck", {
    attendanceTypeId: quickAttendee.attendanceTypeId,
    name: quickAttendee.firstName,
    surname: quickAttendee.lastName,
    birthDate: quickAttendee.birthDate?.toISOString().split("T")[0],
    phone: quickAttendee.phone,
    email: quickAttendee.email,
    identityCode: quickAttendee.nationalId,
    overallSponsor: quickAttendee.overallSponsor,
    note: quickAttendee.note
  });
}

export function createAttendee(attendee) {
  return WebAPI.post("/Attendee/Attendance", {
    academicTitleId: attendee.title === 0 ? null : attendee.title,
    name: attendee.firstName,
    surname: attendee.lastName,
    birthDate: attendee.birthDate?.format("YYYY-MM-DD") || null,
    phone: attendee.phone,
    email: attendee.email,
    genderId: attendee.gender,
    identityCode: attendee.nationalId,
    institutionName: attendee.companyName,
    department: attendee.department,
    position: attendee.position,
    address: attendee.address,
    attendanceTypeId: attendee.type,
    barcodeNumber: attendee.barcode,
    rsvpNumber: attendee.rsvpNo,
    note: attendee.notes,
    notify: attendee.notify,
    countryISO: attendee.country,
    city: attendee.city,
    specialPrice: attendee.pricing?.price,
    registrationPeriodId: toPeriodId(attendee.pricing?.period) || null,
    sponsors: attendee.pricing?.sponsors,
    overallSponsor: attendee.overallSponsor
  });
}

export function deleteAttendee(attendeeId) {
  return WebAPI.delete(`/Attendee/Attendance/${attendeeId}`);
}

export function updateAttendeeInfo(attendeeId, attendee) {
  console.log(attendee);
  return WebAPI.put("/Attendee/Attendance", {
    attendeeId,
    academicTitleId: attendee.title,
    name: attendee.firstName,
    surname: attendee.lastName,
    birthDate: attendee.birthDate?.format("YYYY-MM-DD") || null,
    phone: attendee.phone,
    email: attendee.email,
    genderId: attendee.gender,
    identityCode: attendee.nationalId,
    institutionName: attendee.companyName,
    department: attendee.department,
    position: attendee.position,
    address: attendee.address,
    attendanceTypeId: attendee.type,
    barcodeNumber: attendee.barcode,
    rsvpNumber: attendee.rsvpNo,
    note: attendee.notes,
    notify: attendee.notify,
    countryISO: attendee.country,
    city: attendee.city,
    specialPrice: attendee.pricing?.price,
    registrationPeriodId: toPeriodId(attendee.pricing?.period) || null,
    sponsors: attendee.pricing?.sponsors,
    overallSponsor: attendee.overallSponsor
  });
}

// -- Attendances
export function getAttendeePicture(attendeeId) {
  return WebAPI.get(`/Attendee/AttendeePhoto/${attendeeId}`);
}

export function updateAttendeePicture(attendeeId, pictureData) {
  return WebAPI.put("/Attendee/AttendeePhoto", {
    attendeeId,
    image: pictureData
  });
}

export function checkInAttendee(attendeeId) {
  return WebAPI.post("/Attendee/CheckIn", {
    attendeeId,
    data: new Date().toISOString()
  });
}

export function checkOutAttendee(attendeeId) {
  return WebAPI.post("/Attendee/CheckOut", {
    attendeeId,
    data: new Date().toISOString()
  });
}

export function cancelCheckIn(attendeeId) {
  return WebAPI.post("/Attendee/CancelCheckIn", {
    attendeeId,
    data: new Date().toISOString()
  });
}

export function cancelCheckOut(attendeeId) {
  return WebAPI.post("/Attendee/CancelCheckOut", {
    attendeeId,
    data: new Date().toISOString()
  });
}

// -- Attendee Transfers
export function getAttendeeTransfer(attendeeId) {
  return WebAPI.get(`/Attendee/Transfer/${attendeeId}`);
}

export function updateAttendeeTransfer(attendeeId, transferData) {
  return WebAPI.put("/Attendee/Transfer", {
    attendeeId,
    incoming: {
      originCity: transferData.arrival.fromCity,
      company: transferData.arrival.transportProvider,
      pnr: transferData.arrival.pnr,
      expeditionNo: transferData.arrival.expeditionNo,
      arrivalDate: transferData.arrival.arrivalDate.format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
      transferDate: transferData.arrival.transferStart.format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
      passengerCount: transferData.arrival.passangerCount,
      transferPrice: transferData.arrival.transfer.price,
      sponsors: transferData.arrival.transfer.sponsors,
      transferNote: transferData.arrival.transferNote,
      active: transferData.arrival.enabled
    },
    outgoing: {
      originCity: transferData.departure.toCity,
      company: transferData.departure.transportProvider,
      pnr: transferData.departure.pnr,
      expeditionNo: transferData.departure.expeditionNo,
      arrivalDate: transferData.departure.departureDate.format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
      transferDate: transferData.departure.transferStart.format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
      passengerCount: transferData.departure.passangerCount,
      transferPrice: transferData.departure.transfer.price,
      sponsors: transferData.departure.transfer.sponsors,
      transferNote: transferData.departure.transferNote,
      active: transferData.departure.enabled
    }
  });
}

// -- Attendee Sponsors
export function getAttendeeSponsors(attendeeId) {
  return WebAPI.get(`/Attendee/AttendanceSponsors/${attendeeId}`);
}

// -- Attendee Financial
export function getAttendeeBillingInfo(attendeeId) {
  return WebAPI.get(`/Attendee/BillingInfo/${attendeeId}`);
}

export function updateAttendeeBillingInfo(attendeeId, billingInfo) {
  return WebAPI.put("/Attendee/BillingInfo", {
    attendeeId,
    companyName: billingInfo.name,
    countryISO: billingInfo.countryISO,
    city: billingInfo.city,
    taxOffice: billingInfo.taxOffice,
    taxNumber: billingInfo.taxNumber,
    address: billingInfo.address
  });
}

export function getAttendeeBalance(attendeeId) {
  return WebAPI.get(`/Attendee/Balance/${attendeeId}`);
}

export function addAttendeePayment(attendeeId, payment) {
  return WebAPI.post("/Attendee/Payment", {
    attendeeId,
    amount: payment.wipedAwayDebt,
    paidAmount: payment.paidAmount,
    paidCurrencyIso: payment.paidCurrencyIso,
    description: "-",
    date: payment.date?.format("YYYY-MM-DD"),
    paymentMethod: payment.method, // TODO: <-- Rename if necessary
    paymentCategory:
      payment.category === "service" ? "additionalservice" : payment.category
  });
}

export function removeAttendeePayment(paymentId) {
  return WebAPI.delete(`/Attendee/Payment/${paymentId}`);
}

// -- Attendee Notes
export function getAttendeeNotes(attendeeId) {
  return WebAPI.get(`/Attendee/Note/${attendeeId}`);
}

export function updateAttendeeNotes(attendeeId, notes) {
  return WebAPI.post("/Attendee/Note", {
    attendeeId,
    generalNote: notes?.general?.note,
    generalWarning: notes?.general?.notify,

    registeryNote: notes?.registry?.note,
    registeryWarning: notes?.registry?.notify,

    accommodationNote: notes?.accommodation?.note,
    accommodationWarning: notes?.accommodation?.notify,

    transferNote: notes?.transfer?.note,
    transferWarning: notes?.transfer?.notify
  });
}

export function bulkUpdateNoteNotifications(bulkData) {
  return WebAPI.post("/Attendee/CheckAllNotes", {
    CheckAllGeneralWarning: bulkData.general,
    CheckAllRegisteryWarning: bulkData.registry,
    CheckAllAccommodationWarning: bulkData.accommodation,
    CheckAllTransferWarning: bulkData.transfer
  });
}

// -- Attendee Barcodes / QR Codes
export function regenerateQR(attendeeId) {
  return WebAPI.post(`/Attendee/Attendance/UpdateBarcode/${attendeeId}`);
}
