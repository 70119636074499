import Nprogress from "nprogress";

let progresses = {};

const updateNProgress = () => {
  if (Object.keys(progresses).length === 0) {
    Nprogress.done();
  } else {
    Nprogress.start();
  }
};

export function push(name) {
  if (progresses[name]) {
    progresses[name] += 1;
  } else {
    progresses[name] = 1;
  }
  updateNProgress();
}

export function pop(name) {
  progresses[name] -= 1;
  if (progresses[name] <= 0) {
    delete progresses[name];
  }
  updateNProgress();
}
