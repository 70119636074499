import React from "react";
import flat from "flat";
import _ from "lodash";
import moment from "moment";
import countries from "i18n-iso-countries";
import * as paperEditor from "@igoodie/paper-editor";
import { createIntl, createIntlCache, injectIntl } from "react-intl";
import { FormattedMessage, FormattedDate } from "react-intl";

// TODO: Consider polyfilling for older browsers
// (See https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md#migrate-to-using-native-intl-apis)
// if (!Intl.PluralRules) {
//   require('@formatjs/intl-pluralrules/polyfill');
//   require('@formatjs/intl-pluralrules/dist/locale-data/de'); // Add locale data for de
// }

// if (!Intl.RelativeTimeFormat) {
//   require('@formatjs/intl-relativetimeformat/polyfill');
//   require('@formatjs/intl-relativetimeformat/dist/locale-data/de'); // Add locale data for de
// }

export const fallbackLocale = "en-US";

function updateMoment(locale: string) {
  if (locale !== "en") require(`moment/locale/${locale}`);
  moment.locale(locale);
}

function updateCountries(locale: string) {
  countries.registerLocale(require(`i18n-iso-countries/langs/${locale}.json`));
}

function updatePaperEditor(locale: string) {
  const { messages } =
    require(`./editor-messages/${locale}.js`) ||
    require(`./editor-messages/en.js`);
  paperEditor.setIntlMessages(messages as typeof paperEditor.messagesEn);
}

function loadLocale(locale: string) {
  return flat(require(`i18n/locales/${locale}.json`));
}

const newIntl = (locale: string) => {
  const [language, country] = locale.split("-");

  updateMoment(language);
  updateCountries(language);
  updatePaperEditor(language);

  return Object.assign(
    createIntl({
      locale: language,
      messages: _.merge({}, loadLocale(fallbackLocale), loadLocale(locale))
    }),
    { country }
  );
};

let intl = newIntl(fallbackLocale);

export function updateLocale(locale: string) {
  try {
    const updatedIntl = newIntl(locale);
    Object.assign(intl, updatedIntl); // Prolly the worst hack in the history of men 😒
  } catch (error) {
    console.log(error);
    console.log("Loading fallback language:", fallbackLocale);
    Object.assign(intl, newIntl(fallbackLocale));
  }
}

export function formatCurrency(currency: string, amount: number) {
  return new Intl.NumberFormat(intl.locale, {
    currency,
    style: "currency"
  }).format(amount);
}

// Injected Components
const FormattedMessageInjected = injectIntl(
  (props: any) => <FormattedMessage {...props} />,
  { forwardRef: false }
);

const FormattedDateInjected = injectIntl(
  (props: any) => <FormattedDate {...props} />,
  { forwardRef: false }
);

// Export logic
export default intl;
export {
  FormattedMessageInjected as FormattedMessage,
  FormattedDateInjected as FormattedDate
};
