export function toPeriod(periodId) {
  switch (periodId) {
    case 1:
      return "early";
    case 2:
      return "late";
    case 3:
      return "on-site";
    default:
      return "none";
  }
}

export function toPeriodId(period) {
  switch (period) {
    case "early":
      return 1;
    case "late":
      return 2;
    case "on-site":
      return 3;
    default:
      return null;
  }
}
