import { API as WebAPI } from "remote/api/WebAPI";
import _ from "lodash";

export function getSettingsBatched() {
  return WebAPI.get("/Setting/OrganizationSettings");
}

export function getAttendeeInfoFields() {
  return WebAPI.get("/Setting/GetAttendanceRequirements");
}

export function saveAttendeeInfoFields(fields) {
  return WebAPI.put(
    "Setting/SetAttendanceRequirements",
    _.map(fields, (value, key) => ({
      fieldName: _.upperFirst(key),
      visible: value.visible,
      required: value.required
    }))
  );
}

export function savePreferredCurrencies(currencies) {
  return WebAPI.post("/Setting/OrganizationSettings", {
    currencies: {
      attendanceCurrencyISO: currencies.registration,
      accommodationCurrencyISO: currencies.accommodation,
      // transporCurrencyISO: currencies.travel,
      transferCurrencyISO: currencies.transfer,
      activityCurrencyISO: currencies.activity,
      courseCurrencyISO: currencies.course,
      additionalServiceCurrencyISO: currencies.extraService
    }
  });
}

export function savePreferences(preferences) {
  return WebAPI.post("/Setting/OrganizationSettings", {
    preferences: {
      allowEarlyCheckIn: preferences.allowEarlyCheckIn,
      customRoomPricing: preferences.customRoomPricing,
      detectOptimalPricingPackage: preferences.detectOptimalPricingPackage
    }
  });
}

export function markAttendanceBadge(templateId) {
  return WebAPI.post("/Setting/OrganizationSettings", {
    printingConfigurations: {
      badgeTemplateId: templateId
    }
  });
}

export function saveStandardPricings(pricings) {
  return WebAPI.post("/Setting/OrganizationSettings", {
    preDefinedPrices: {
      transferPrice: pricings.transfer
    }
  });
}
