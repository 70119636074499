import { createSlice } from "@reduxjs/toolkit";
import { takeLatest, takeEvery, put, call } from "redux-saga/effects";
import _ from "lodash";

import { actions as AuthActions } from "store/duck/auth.duck";

import { getAttendeeInfoFields } from "remote/crud/settings.crud";
import { getSettingsBatched } from "remote/crud/settings.crud";

import { notifyError } from "util/toast.util";
import * as NProgressUtil from "util/nprogress.util";
import { fieldsResponseMapper } from "apps/event-app/routes/Settings/AttendeeInfoDefinitions/resources";

const name = "settings";

const initialState = {
  pending: [],
  errors: {},
  settings: {
    attendeeInfoFields: null,
    preferredCurrencies: null,
    preferences: null,
    printingConfigs: null,
    pricingStandards: null
  }
};

const blacklist = ["pending", "errors"];

const slice = createSlice({
  name,
  initialState,
  reducers: {
    fetch_request: (state, action) => {
      const { setting } = action.payload;
      state.pending.push(setting);
    },

    fetch_fail: (state, action) => {
      const { setting, error } = action.payload;
      const index = state.pending.indexOf(setting);
      state.pending.splice(index, 1);
      state.errors[setting] = error;
    },

    update_setting: (state, action) => {
      const { setting, data } = action.payload;
      const index = state.pending.indexOf(setting);
      if (index !== -1) state.pending.splice(index, 1);
      state.settings = { ...state.settings, ...data };
    }
  }
});

const selectors = {
  getLoadingPercentage: state =>
    _.values(state[name].settings).filter(s => s !== null).length /
    Object.keys(state[name].settings).length,

  getPending: state => state[name].pending,

  getSettings: state => state[name].settings,

  getErrors: state => state[name].errors,

  getAttendeeInfoFields: state => state[name].settings.attendeeInfoFields,
  getPreferredCurrencies: state => state[name].settings.preferredCurrencies,
  getPreferences: state => state[name].settings.preferences,
  getPrintingConfigs: state => state[name].settings.printingConfigs,
  getStandardPricings: state => state[name].settings.pricingStandards,

  pendingAttendeeInfoFields: state =>
    state[name].pending.includes("attendeeInfoFields") ||
    state[name].settings.attendeeInfoFields === null
};

const sagaWorkers = {
  fetchSetting: function*({ setting, fetcher, mapper }) {
    try {
      NProgressUtil.push(setting);
      const response = yield call(fetcher);
      const { data } = response;
      yield put(
        slice.actions.update_setting({
          setting,
          data: mapper ? mapper(data) : data
        })
      );
    } catch (error) {
      yield notifyError({
        errorCode: error.response?.status,
        message: "TODO: Notify error!",
        autoClose: false
      });
      yield put(slice.actions.fetch_fail({ setting, error }));
    } finally {
      NProgressUtil.pop(setting);
    }
  }
};

const saga = function*(getState) {
  yield takeEvery([slice.actions.fetch_request], function*(action) {
    const { setting } = action.payload;
    if (setting === "attendeeInfoFields")
      yield sagaWorkers.fetchSetting({
        setting,
        fetcher: () => getAttendeeInfoFields(),
        mapper: result => ({ attendeeInfoFields: fieldsResponseMapper(result) })
      });
    else if (setting === "settingsBatched")
      yield sagaWorkers.fetchSetting({
        setting,
        fetcher: () => getSettingsBatched(),
        mapper: result => ({
          preferredCurrencies: {
            registration: result.currencies.attendanceCurrencyISO,
            accommodation: result.currencies.accommodationCurrencyISO,
            // travel: result.currencies.transporCurrencyISO,
            transfer: result.currencies.transferCurrencyISO,
            course: result.currencies.courseCurrencyISO,
            activity: result.currencies.activityCurrencyISO,
            extraService: result.currencies.additionalServiceCurrencyISO
          },
          preferences: {
            allowEarlyCheckIn: result.preferences.allowEarlyCheckIn,
            customRoomPricing: result.preferences.customRoomPricing,
            detectOptimalPricingPackage:
              result.preferences.detectOptimalPricingPackage
          },
          printingConfigs: {
            attendanceBadgeId: result.printingConfigurations.badgeTemplateId
          },
          pricingStandards: {
            transfer: result.preDefinedPrices.transferPrice
          }
        })
      });
  });
};

export const { reducer, actions } = slice;
export { selectors, saga };
export { blacklist };
export default slice;
