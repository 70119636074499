import React from "react";

import {
  actions as LayoutActions,
  selectors as LayoutSelectors
} from "store/duck/layout.duck";
import { connect } from "react-redux";

const PersistLoader = ({ locale, switch_language }) => {
  React.useEffect(() => {
    return () => switch_language(locale);
  });

  return <div>Waiting for persistor..</div>; // TODO: Beautify this indicator
};

const mapStateToProps = state => ({
  locale: LayoutSelectors.getLocale(state)
});

const mapDispatchToProps = dispatch => ({
  switch_language: locale => dispatch(LayoutActions.switch_language(locale))
});

export default connect(mapStateToProps, mapDispatchToProps)(PersistLoader);
