import React from "react";
import { connect } from "react-redux";

import classes from "util/classes.util";
import { selectors as LayoutSelectors } from "store/duck/layout.duck";
import { actions as LayoutActions } from "store/duck/layout.duck";

import Slider from "@material-ui/core/Slider";
import "./style.scss";

const zoomMarks = [
  { value: 0.8, label: "80%" },
  { value: 0.85, label: "85%" },
  { value: 0.9, label: "90%" },
  { value: 0.95, label: "95%" },
  { value: 1.0, label: "100%" }
];

const zoomClasses = {
  root: "dc-layout-adjuster__slider__root",
  markLabel: "dc-layout-adjuster__slider__marklabel"
};

const LayoutAdjuster = ({ zoom, changeZoom }) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const clickAwayHandler = event =>
      !event.target.closest(".dc-layout-adjuster") && setOpen(false);
    document.addEventListener("click", clickAwayHandler);
    return () => document.removeEventListener("click", clickAwayHandler);
  }, []);

  return (
    <div
      className={classes([
        "dc-layout-adjuster",
        open && "dc-layout-adjuster--open"
      ])}
      style={{ zoom: 1.0 / zoom }}
    >
      {/* <i className="fas fa-sliders-h" /> */}
      <div className="dc-layout-adjuster__head" onClick={() => setOpen(!open)}>
        <i className="fas fa-chevron-left" />
        <i className="zmdi zmdi-settings zmdi-hc-spin" />
        <h1>Layout Preferences</h1>
      </div>

      <hr />

      <div className="dc-layout-adjuster__content">
        <h2>
          <i className="fas fa-search" /> Zoom
        </h2>
        <Slider
          classes={zoomClasses}
          defaultValue={zoom ?? 1.0}
          valueLabelFormat={value => 100 * value + "%"}
          step={null}
          min={0.8}
          max={1.0}
          marks={zoomMarks}
          valueLabelDisplay="auto"
          onChange={(e, value) => changeZoom(value)}
        />
        <span className="dc-layout-adjuster__content__warn">
          <i className="fas fa-flask" />
          <h5>
            <strong>Experimental:</strong> might not work on every browser.{" "}
            <br />
            In case, use your browser's zooming feature
          </h5>
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  zoom: LayoutSelectors.getZoom(state)
});

const mapDispatchToProps = dispatch => ({
  changeZoom: zoom => dispatch(LayoutActions.change_zoom(zoom))
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutAdjuster);
