import { API as WebAPI } from "remote/api/WebAPI";

export function getRoles() {
  return WebAPI.get("/Users/Roles");
}

export function getPermissions(workgroupId, eventId) {
  return WebAPI.get("/permissions", {
    workgroupId,
    eventId
  });
}
