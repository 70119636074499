import React from "react";
import _ from "lodash";

import classes from "util/classes.util";
import { SaveNotifierContext } from "hooks/save-notifier.context";

import DigiconSpinner from "components/DigiconSpinner";
import { FormattedMessage } from "i18n";
import "./style.scss";

function SaveNotifier(props) {
  const context = React.useContext(SaveNotifierContext);
  const resources = context.getResources();
  const pending = Object.keys(resources).some(id => resources[id].pending);

  // -- Callback funcs
  const onAnimationEnd = event => {
    if (event.animationName === "shake") {
      context.shakeNotifier(false);
    }
  };

  const onDiscardClicked = () => {
    Object.keys(resources).forEach(id => resources[id].onReset());
  };

  const onSaveClicked = () => {
    // Top 10 Amazing Cheesy Hacks of 2020
    // Test if at least one error is present
    for (let resourceId in resources) {
      // Will also focus on invalid fields (?)
      if (!_.isEmpty(resources[resourceId].errors)) {
        resources[resourceId].handleSubmit(() => {});
        context.shakeNotifier(true);
        return; // stop here
      }
    }

    // Perform saving all
    Object.keys(resources).forEach(
      id => resources[id].dirty && resources[id].onSave()
    );
  };

  // -- Render
  return (
    <div
      className={classes([
        "dc-resource-saver",
        context.hasUnsavedResources() && "dc-resource-saver--visible",
        context.isShaking() && "dc-resource-saver--shake"
      ])}
      onAnimationEnd={onAnimationEnd}
    >
      <h2 className="dc-resource-saver__text">
        <i className="zmdi zmdi-alert-triangle dc-resource-saver__icon" />
        <strong>
          <FormattedMessage id="container.savenotifier.careful" />
        </strong>{" "}
        —{" "}
        <em>
          <FormattedMessage id="container.savenotifier.message" />
        </em>
      </h2>
      <button
        disabled={pending}
        className="dc-resource-saver__reset"
        onClick={onDiscardClicked}
      >
        <FormattedMessage id="container.savenotifier.discard" />
      </button>
      {pending ? (
        <DigiconSpinner length={"35px"} />
      ) : (
        <button
          disabled={pending}
          className="dc-resource-saver__save"
          onClick={onSaveClicked}
        >
          <FormattedMessage id="container.savenotifier.save" />
        </button>
      )}
    </div>
  );
}

export default SaveNotifier;
