import React from "react";

import LogoImg from "assets/images/logo.png";
import { ConditionalWrap } from "util/components.util";
import "./style.scss";

const DigiconSpinnerWrapper = children => {
  return <div className="dc-spinner-wrapper">{children}</div>;
};

const DigiconSpinner = ({ length = "200px", isLiquid, ...others }) => {
  return (
    <ConditionalWrap condition={isLiquid} wrapper={DigiconSpinnerWrapper}>
      <div className="dc-spinner" style={{ "--length": length }} {...others}>
        <img src={LogoImg} />
      </div>
    </ConditionalWrap>
  );
};

export default DigiconSpinner;
