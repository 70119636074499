import { createSlice } from "@reduxjs/toolkit";
import { put, call, takeLatest } from "redux-saga/effects";
import _ from "lodash";

import { getPermissions } from "remote/crud/perms.crud";

const name = "perms";

const initialState = {
  loading: false,
  error: null,
  data: {
    agencyPerms: [],
    workgroupPerms: [],
    roles: [
      // {
      //   name: "Registration Desk Member",
      //   perms: [0x1, 0x3, 0xA]
      // }
    ]
  }
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    request_perms: store => ({
      ...store,
      loading: true
    }),

    update_perms: (store, action) => ({
      ...store,
      loading: false,
      data: action.payload
    }),

    fail_perms: (store, action) => ({
      ...store,
      loading: false,
      error: action.payload
    })
  }
});

const selectors = {};

const sagaWorkers = {
  fetchPerms: function*(action) {
    const { update_perms, fail_perms } = slice.actions;
    const { workgroupId, eventId } = action.payload;
    try {
      const response = yield call(getPermissions, workgroupId, eventId);
      yield put(update_perms(response.data));
    } catch (error) {
      yield put(fail_perms(error.response ? error.response.data : {}));
      // TODO: Decide what to happen further
    }
  }
};

const saga = function*() {
  const { request_perms } = slice.actions;

  yield takeLatest(request_perms.type, sagaWorkers);
};

export const { reducer, actions } = slice;
export { selectors, saga };
export default slice;
