import React from "react";
import { connect } from "react-redux";

import { selectors as NavigationSelectors } from "store/duck/navigation.duck";
import { actions as NavigationActions } from "store/duck/navigation.duck";

import DigiconSpinner from "components/DigiconSpinner";
import intl, { FormattedMessage } from "i18n";
import "./style.scss";

const SettingsLoader = props => {
  const {
    percentage,
    text,
    activeWorkgroup,
    activeEvent,
    pending,
    fetched,
    ...settingsCRUD
  } = props;

  React.useEffect(() => {
    if (!fetched && !pending) {
      settingsCRUD.request();
    }
  }, [pending, fetched]);

  return (
    <div className="dc-settings-loader">
      <DigiconSpinner />
      <h2>{text}</h2>
      {percentage !== 0.0 && (
        <progress value={percentage} max={1.0}>
          {percentage * 100}%
        </progress>
      )}
      <h3>{intl.formatMessage({ id: "loader.pleasewait" })}</h3>

      {/* NOTE: Can add Protip div here! */}
    </div>
  );
};

const mapStateToProps = state => ({
  activeWorkgroup: NavigationSelectors.getActiveWorkgroup(state),
  activeEvent: NavigationSelectors.getActiveEvent(state),
  pending: NavigationSelectors.isPending(state),
  fetched: NavigationSelectors.isFetched(state)
});

const mapDispatchToProps = dispatch => ({
  request: () => dispatch(NavigationActions.fetch_request())
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsLoader);
