import * as yup from "yup";
import _ from "lodash";

import { getAttendeeTitles } from "remote/crud/attendees.crud";

// -- CRUD
export const titlesResourceOptions = {
  immediate: false,
  listFetcher: () => getAttendeeTitles(),
  listFetchMapper: result =>
    result.map(title => ({
      titleId: title.academicTitleId,
      name: title.name
    }))
};

// -- Default forms
export const titleFormOptions = {
  immediate: true,
  resourceFetcher: () => ({
    name: "",
    description: ""
  })
};

// -- Mapper
export const titleSaveResponse = response => ({
  titleId: response.data.academicTitleId,
  name: response.data.name
});

const fieldsSearch = (fields, fieldName) =>
  _.find(fields, { fieldName }) || {
    fieldName,
    visible: true,
    required: false
  };

export const fieldsResponseMapper = result => ({
  title: fieldsSearch(result, "Title"),
  birthDate: fieldsSearch(result, "BirthDate"),
  gender: fieldsSearch(result, "Gender"),
  phone: fieldsSearch(result, "Phone"),
  email: fieldsSearch(result, "Email"),
  country: fieldsSearch(result, "Country"),
  city: fieldsSearch(result, "City"),
  companyName: fieldsSearch(result, "CompanyName"),
  department: fieldsSearch(result, "Department"),
  position: fieldsSearch(result, "Position"),
  address: fieldsSearch(result, "Address"),
  rsvpNo: fieldsSearch(result, "RsvpNo"),
  nationalId: fieldsSearch(result, "NationalId")
});

// Validation Schemas
// prettier-ignore
export const titleValidationSchema = yup.object().shape({
  name: yup.string()
    .required()
})
