import axios from "axios";
import { history } from "store";
import { AuthTokenCookie } from "store/cookies";
const API = axios.create({
  baseURL: "https://veri.workindigi.com",
  timeout: 10_000
});

// Auth token interceptors
API.interceptors.request.use(request => {
  const authToken = AuthTokenCookie.get();
  if (authToken) {
    request.headers.Authorization = `Bearer ${authToken}`;
  }
  return request;
});
API.interceptors.response.use(null, err => {
  if (err.response.status === 401) {
    AuthTokenCookie.erase();
    history.push("/login"); // TODO: Test this 🤔
  }
  return Promise.reject(err);
});

export { API };
