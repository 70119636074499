import _ from "lodash";
import React from "react";
import { Redirect, Route } from "react-router-dom";

const RestrictedRoute = ({ predicates, ...other }) => {
  if (!predicates || !_.isArray(predicates)) {
    throw Error("<RestrictedRoute /> requires predicates prop as an Array!");
  }

  for (let predicate in predicates) {
    const { requirement, redirection, push } = predicates[predicate];

    if (!requirement || !redirection) {
      throw Error(
        "Predicates array should consist of objects with 'requirement' and 'redirection' fields."
      );
    }

    if (!requirement()) {
      console.log("Redirection:", redirection);
      if (_.isString(redirection))
        return <Redirect push={push} to={redirection} />;
      if (_.isObject(redirection))
        return <Redirect push={push} to={redirection} />;
      return <redirection />; // Assuming it is a Component
    }
  }

  return <Route {...other} />;
};

export default RestrictedRoute;
