import React from "react";
import ReactDOM from "react-dom";

// Why did you render big-boi initializer
if (process.env.NODE_ENV === "development") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  const ReactRedux = require("react-redux");
  whyDidYouRender(React, {
    trackAllPureComponents: false,
    trackHooks: false,
    trackExtraHooks: [[ReactRedux, "useSelector"]]
  });
}

// Resource Debugger
if (process.env.NODE_ENV === "development") {
  window.JSON_DEBUGGER = require("components/____DEBUG/ResourceDebugger").JsonDebugger;
  window.RESOURCE_DEBUGGER = require("components/____DEBUG/ResourceDebugger").default;
}

// Fetch root node from DOM
const root = document.getElementById("app-site");

// Render the root component
let render = () => {
  const AppsRoot = require("apps/apps.root").default;
  ReactDOM.render(<AppsRoot />, root);
};

render();
