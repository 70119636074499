import React from "react";
import { toast, cssTransition } from "react-toastify";

import "styles/toast/toast.scss";

const Toast = ({ icon, message, errorCode }) => {
  return (
    <div className="toast-container">
      <i className={`zmdi zmdi-${icon} toast-container__icon`} />
      <span className="toast-container__content">
        {errorCode && <strong>{errorCode} - </strong>}
        {message}
      </span>
    </div>
  );
};

const ToastTransition = cssTransition({
  enter: "toast-enter",
  exit: "toast-out",
  duration: [300, 600]
});

export function notifySuccess({ message, ...options }) {
  toast(<Toast icon="check" message={message} />, {
    type: toast.TYPE.SUCCESS,
    transition: ToastTransition,
    ...options
  });
}

/**
 *
 * @param {{
 *  [x: string]: any,
 *  message: any,
 *  errorCode?:any
 * }} param0
 */
export function notifyError({ message, errorCode, ...options }) {
  toast(
    <Toast icon="alert-triangle" message={message} errorCode={errorCode} />,
    {
      type: toast.TYPE.ERROR,
      transition: ToastTransition,
      ...options
    }
  );
}

export default toast;
