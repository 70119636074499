import React from "react";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { IntlProvider } from "react-intl";

import intl from "i18n";
import RTL from "util/RTL";
import { AsyncComponentHOC } from "util/components.util";
import defaultTheme from "containers/themes/defaultTheme";
import RestrictedRoute from "util/RestrictedRoute";
import { selectors as LayoutSelectors } from "store/duck/layout.duck";
import { selectors as NavigationSelectors } from "store/duck/navigation.duck";
import { AuthTokenCookie } from "store/cookies";

import "assets/vendors/style";
import NavSettingsLoader from "components/NavSettingsLoader";
import LayoutAdjuster from "components/LayoutAdjuster";
import ResourceSaveNotifier from "components/ResourceSaveNotifiers";

const RoutersRoot = props => {
  const { userId, match, location, isDirectionRTL, navSettingsFetched } = props;
  const { zoom } = props;
  const { activeEvent, activeWorkgroup } = props;

  const applyTheme = createTheme(defaultTheme);

  React.useEffect(() => {
    document.getElementsByTagName("html")[0].style.zoom = zoom;
  }, [zoom]);

  // -- Restrictions. (Might be optimized with useMemo)
  const loggedInRestriction = React.useMemo(
    () => ({
      requirement: () => userId !== null && AuthTokenCookie.exists(),
      redirection: {
        pathname: `${match.url}login`,
        state: { referrer: location }
      },
      push: true
    }),
    [userId, AuthTokenCookie.exists()]
  );

  const activeEventRestriction = React.useMemo(
    () => ({
      requirement: () => activeWorkgroup.id !== null && activeEvent.id !== null,
      redirection: {
        pathname: `${match.url}agency/workgroups`,
        state: { referrer: location }
      },
      push: true
    }),
    [activeWorkgroup.id, activeEvent.id]
  );

  // -- Side effects
  React.useEffect(() => {
    return () => (window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true);
  }, []);

  // -- Render
  if (AuthTokenCookie.exists() && !navSettingsFetched) {
    return (
      <IntlProvider locale={intl.locale} messages={intl.messages}>
        <NavSettingsLoader
          percentage={navSettingsFetched ? 1.0 : 0.0}
          text={intl.formatMessage({ id: "loader.fetchingwg" })}
        />
      </IntlProvider>
    );
  }

  if (isDirectionRTL) {
    applyTheme.direction = "rtl";
    document.body.classList.add("rtl");
  } else {
    document.body.classList.remove("rtl");
    applyTheme.direction = "ltr";
  }

  return (
    <MuiThemeProvider theme={applyTheme}>
      <IntlProvider locale={intl.locale} messages={intl.messages}>
        <MuiPickersUtilsProvider
          libInstance={moment}
          utils={MomentUtils}
          locale={intl.locale}
        >
          <RTL>
            <div className="app-main" lang={intl.locale}>
              <ResourceSaveNotifier />
              <Switch>
                {/* TODO: Re-consider? */}
                <Redirect exact from={`${match.url}`} to={`${match.url}app`} />

                {/* TODO: Landing */}
                {/* <Route
                  path={`${match.url}`}
                  component={AsyncComponentHOC(() =>
                    import("path/to/landing-page")
                  )}
                /> */}

                {/* TODO: Register */}
                {/* <Route
                  path={`${match.url}register`}
                  component={AsyncComponentHOC(() =>
                    import("path/to/register-page")
                  )}
                /> */}

                {/* Login */}
                <Route
                  path={`${match.url}login`}
                  component={AsyncComponentHOC(() =>
                    import("containers/Login")
                  )}
                />

                {/* Agency App */}
                <RestrictedRoute
                  path={`${match.url}agency`}
                  component={AsyncComponentHOC(() => import("apps/agency-app"))}
                  predicates={[loggedInRestriction]}
                />

                {/* Event App */}
                <RestrictedRoute
                  path={`${match.url}app`}
                  component={AsyncComponentHOC(() => import("apps/event-app"))}
                  predicates={[loggedInRestriction, activeEventRestriction]}
                />

                {/* 404 - No App Found */}
                <Route
                  component={AsyncComponentHOC(() =>
                    import("components/Error404")
                  )}
                />
              </Switch>
            </div>
          </RTL>
          <LayoutAdjuster />
        </MuiPickersUtilsProvider>
      </IntlProvider>
    </MuiThemeProvider>
  );
};

const mapStateToProps = state => ({
  userId: state.auth.user.userId,
  sideNavColor: state.layout.sideNavColor,
  locale: state.auth.user.language,
  isDirectionRTL: state.layout.isDirectionRTL,
  activeWorkgroup: NavigationSelectors.getActiveWorkgroup(state),
  activeEvent: NavigationSelectors.getActiveEvent(state),
  navSettingsFetched: NavigationSelectors.isFetched(state),
  zoom: LayoutSelectors.getZoom(state)
});

export default connect(mapStateToProps)(RoutersRoot);
