import React from "react";

import { ConditionalWrap } from "util/components.util";
import classes from "util/classes.util";

import "./style.scss";

const CircularProgressFluidWrapper = children => {
  return <div className="circular-progress-fluidwrapper">{children}</div>;
};

/**
 *
 * @param {{
 * className?: string,
 * isFluid?: boolean,
 * radius?: string
 * }} param0
 * @returns
 */
const CircularProgress = ({
  className = undefined,
  isFluid = false,
  radius = "60px"
}) => (
  <ConditionalWrap wrapper={CircularProgressFluidWrapper} condition={isFluid}>
    <div
      className={classes(["loader", className])}
      style={{ width: radius, height: radius }}
    >
      <svg className="circular" viewBox="25 25 50 50">
        <circle
          className="path"
          cx="50"
          cy="50"
          r="15"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <circle
          cx="50"
          cy="50"
          r="15"
          fill="none"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          stroke="#7e71999e"
        />
      </svg>
    </div>
  </ConditionalWrap>
);

export default CircularProgress;
