import { all } from "redux-saga/effects";

// Sagas from Ducks
import { saga as LayoutSaga } from "store/duck/layout.duck";
import { saga as AuthSaga } from "store/duck/auth.duck";
import { saga as NavigationSaga } from "store/duck/navigation.duck";
import { saga as PermsSaga } from "store/duck/perms.duck";
import { saga as SettingsSaga } from "store/duck/settings.duck";

// Root watcher
const rootSaga = function*(getState) {
  yield all([
    LayoutSaga(),
    AuthSaga(),
    NavigationSaga(),
    PermsSaga(),
    SettingsSaga()
  ]);
};

export default rootSaga;
