const cookies = require("browser-cookies");

// Configure
// cookies.defaults.secure = true; // TODO: Depend on environment
cookies.defaults.expires = 7; // days

// Helper
const createCookieController = (key, expires = 0) => ({
  get: () => cookies.get(key),
  set: (value, options) => cookies.set(key, value, { expires, ...options }),
  exists: function() {
    return !!this.get();
  },
  erase: () => cookies.erase(key)
});

// Create and export cookies
export const AuthTokenCookie = createCookieController("authToken", 1);

export default cookies.all;
